import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import firebase from 'firebase/app';
import 'firebase/auth';

import { UserEntity } from '../../entities';
import { AuthProviderService, IAuthenticationService, UserInfo } from '../interfaces';
import User = firebase.User;
import Persistence = firebase.auth.Auth.Persistence;

@Injectable()
export class AuthenticationService extends IAuthenticationService {
  private firebaseUserSubject$ = new ReplaySubject<User>(1);
  private tokenSubject$ = new ReplaySubject<string>(1);
  private isReadySubject$ = new BehaviorSubject<boolean>(false);

  private firebaseUser: User;

  constructor(private angularFireAuth: AngularFireAuth) {
    super();

    this.angularFireAuth.setPersistence(Persistence.LOCAL);
    this.firebaseUserSubject$.subscribe((firebaseUser) => (this.firebaseUser = firebaseUser));
  }

  private _currentUser$ = this.firebaseUserSubject$.pipe(
    tap((user) => user.getIdToken()),
    distinctUntilChanged((a, b) => a?.uid === b?.uid),
    switchMap((user) => (user ? UserEntity.read<UserEntity>(user.uid) : of(null))),
  );

  get currentUser$(): Observable<UserEntity> {
    return this._currentUser$;
  }

  get token$(): Observable<string> {
    return this.tokenSubject$.asObservable();
  }

  get isReady$(): Observable<boolean> {
    return this.isReadySubject$.asObservable();
  }

  get isAuthenticated$(): Observable<boolean> {
    return combineLatest([this.firebaseUserSubject$, this.token$]).pipe(map(([user, token]) => !!user && !!token));
  }

  get isReady(): boolean {
    return this.isReadySubject$.value;
  }

  get isAuthenticated(): boolean {
    return !!this.firebaseUser;
  }

  get login$(): Observable<string> {
    return this.firebaseUserSubject$.pipe(map((user) => user.email));
  }

  get login(): string {
    return this.firebaseUser.email;
  }

  initialize(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.angularFireAuth.user
        .pipe(
          tap((_) => {
            this.isReadySubject$.next(true);
            resolve();
          }),
        )
        .subscribe(this.firebaseUserSubject$);

      this.angularFireAuth.idToken.subscribe(this.tokenSubject$);
    });
  }

  async register(userInfo: UserInfo): Promise<void> {
    const user = new UserEntity();

    user.id = this.firebaseUser.uid;
    user.provider = this.firebaseUser.providerId;

    user.firstname = userInfo.firstname;
    user.lastname = userInfo.lastname;
    user.pseudo = userInfo.pseudo;
    user.discord = userInfo.discord;
    user.email = userInfo.email;
    user.countryId = userInfo.countryId;

    await user.save();
  }

  async signIn(authProvider: AuthProviderService): Promise<void> {
    try {
      await authProvider.execute();
    } catch (e) {
      throw new Error('SignIn failed');
    }
  }

  async signOut(): Promise<void> {
    await this.angularFireAuth.signOut();
  }
}
