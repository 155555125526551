import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'intNoZero',
})
export class IntNoZeroPipe implements PipeTransform {
  transform(val: number): number | string {
    return val ?? '-';
  }
}
