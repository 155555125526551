import { Pipe, PipeTransform } from '@angular/core';
import { RegistrationEntity } from '../entities';

@Pipe({
  name: 'alreadyRegistered',
})
export class AlreadyRegisteredPipe implements PipeTransform {
  transform(userId: string, registrations: RegistrationEntity[]): boolean {
    return registrations.some((registration) => registration.userId === userId);
  }
}
