import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-reload-modal',
  templateUrl: './reload-modal.component.html',
  styleUrls: ['./reload-modal.component.scss']
})
export class ReloadModalComponent {

  @Input() message;

  timer = 30;

  constructor(public activeModal: NgbActiveModal) {
    setInterval(() => this.timer--, 1000);
  }
}
