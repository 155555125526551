import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, isObservable, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class PaginationService<T> {
  private itemsSubject$ = new ReplaySubject<T[]>(1);
  private itemsPerPageSubject$ = new ReplaySubject<number>(1);
  private currentPageSubject$ = new BehaviorSubject(1);
  private itemsSubscription: Subscription;

  readonly pageCount$ = combineLatest([this.itemsSubject$, this.itemsPerPageSubject$]).pipe(
    map(([items, itemsPerPage]) => Math.ceil(items.length / itemsPerPage)),
  );

  readonly isFirstPage$ = this.currentPage$.pipe(map((currentPage) => currentPage === 1));
  readonly isLastPage$ = combineLatest([this.currentPage$, this.pageCount$]).pipe(
    map(([currentPage, pageCount]) => currentPage === pageCount),
  );

  get currentPage$(): Observable<number> {
    return this.currentPageSubject$.asObservable();
  }

  get itemsPerPage$(): Observable<number> {
    return this.itemsPerPageSubject$.asObservable();
  }

  setItems(items: T[] | Observable<T[]>): void {
    if (!isObservable(items)) {
      items = of(items);
    }

    this.itemsSubscription?.unsubscribe();
    this.itemsSubscription = items.subscribe(this.itemsSubject$);
  }

  setItemsPerPage(value: number): void {
    this.itemsPerPageSubject$.next(value);
  }

  async goToPage(page: number): Promise<void> {
    this.currentPageSubject$.next(Math.max(1, Math.min(await this.pageCount$.pipe(take(1)).toPromise(), page)));
  }

  previousPage(): void {
    this.goToPage(this.currentPageSubject$.value - 1);
  }

  async nextPage(): Promise<void> {
    this.goToPage(this.currentPageSubject$.value + 1);
  }

  firstPage(): void {
    this.goToPage(1);
  }

  async lastPage(): Promise<void> {
    this.goToPage(await this.pageCount$.pipe(take(1)).toPromise());
  }
}
