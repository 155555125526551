import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatchEntity, OrganizerEntity, RegistrationEntity, TournamentEntity, UserEntity } from '../../entities';
import { ScoreEntity } from '../../entities/score.entity';

export abstract class ITournamentService {
  abstract readonly tournament$: Observable<TournamentEntity>;
  abstract readonly registrations$: Observable<RegistrationEntity[]>;
  abstract readonly organizers$: Observable<OrganizerEntity[]>;
  abstract readonly usersNotIn$: Observable<UserEntity[]>;
  abstract readonly usersNotOrganizer$: Observable<UserEntity[]>;
  abstract readonly sortedPlayers$: Observable<RegistrationEntity[]>;
  abstract readonly roundMaxCount$: Observable<number>;
  abstract readonly allScores$: Observable<Map<number, ScoreEntity[]>>;
  abstract readonly canCreateNewRound$: Observable<boolean>;
  abstract readonly rounds$: Observable<Map<number, MatchEntity[]>>;
  abstract readonly treeMatches$: Observable<MatchEntity[]>;
  abstract readonly treeMatchesMap$: Observable<Map<number, MatchEntity[]>>;
  abstract readonly roundCount$: Observable<number>;
  abstract readonly currentRound$: Observable<number>;
  abstract readonly selectedRound$: Observable<number>;
  abstract readonly isCurrentRound$: Observable<boolean>;
  abstract readonly areCurrentRoundScoresEntered$: Observable<boolean>;
  abstract readonly canCancelLastRound$: Observable<boolean>;
  abstract readonly tournamentIsOngoing$: Observable<boolean>;
  abstract readonly availablePowersOfTwo$: Observable<number[]>;
  abstract readonly canDrop$: Observable<Map<string, boolean>>;
  abstract readonly canEdit$: Observable<boolean>;
  abstract readonly canUndrop$: Observable<Map<string, boolean>>;
  abstract readonly playersDropped$: Observable<Map<string, boolean>>;
  abstract readonly isPending$: Observable<Map<number, boolean>>;

  abstract isLoading: Subject<boolean>;
  abstract autoScoreCalculation: BehaviorSubject<boolean>;

  abstract init(tournamentId: number): void;

  abstract selectRound(round: number): void;

  abstract playerMatchesWinDiff(
    registrationId1: number,
    registrationId2: number,
    roundIndexMax: number | undefined,
  ): Observable<number | false>;

  abstract newRound(): Promise<void>;

  abstract cancelRound(): Promise<void>;

  abstract cancelTreePhase(): Promise<void>;

  abstract newTree(powerOfTwo: number): Promise<void>;

  abstract validateMatchesPair(
    matchId1: number,
    match1registration1Points: number,
    match1registration2Points: number,
    matchId2: number,
    match2registration1Points: number,
    match2registration2Points: number,
  ): Promise<void>;

  abstract validateMatch(
    matchId: number,
    registration1Points: number,
    registration2Points: number,
    editMode: boolean,
    registration1Id?: number,
    registration2Id?: number,
    invalidate?: boolean,
    treeMode?: boolean,
    lastMatch?: boolean,
    temporary?: boolean,
  ): Promise<void>;

  abstract closeTournament(tournament?: TournamentEntity): Promise<void>;

  abstract deleteTournament(tournament?: TournamentEntity): Promise<void>;

  abstract toggleDropPlayer(userId: string): Promise<void>;

  abstract generateAndGetScores(): Promise<void>;
}

export enum MatchState {
  Pending,
  Validated,
  Bye,
}

export type PlayerToPair = {
  player: RegistrationEntity;
  id: number;
  matchPoints: number;
  byeCount: number;
  alreadyPlayedOpponents: MatchEntity[];
  canBye: boolean;
};
