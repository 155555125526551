import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AvatarModule, AvatarService } from './avatar';
import { FormModule } from './form';
import { PipesModule } from './pipes';
import { SpinnerModule, SpinnerService } from './spinner';
import { ConfirmationModalModule } from './confirmation-modal/confirmation-modal.module';
import {ReloadModalModule} from './reload-modal/reload-modal.module';

@NgModule({
  imports: [CommonModule, AvatarModule, FormModule, PipesModule, SpinnerModule, ConfirmationModalModule, ReloadModalModule],
  exports: [AvatarModule, FormModule, PipesModule, SpinnerModule],
})
export class UiModule {
  static forRoot(): ModuleWithProviders<UiModule> {
    return {
      ngModule: UiModule,
      providers: [SpinnerService, AvatarService],
    };
  }
}
