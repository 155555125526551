import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { UserEntity } from '../entities';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'get10MostRecentUsers',
})
export class Get10MostRecentUsersPipe implements PipeTransform {
  transform(users$: Observable<UserEntity[]>): Observable<UserEntity[]> {
    return users$.pipe(
      map((users: UserEntity[]) => {
        const filteredUsers = users.sort((a, b) => Math.sign(b.registrationDate.getTime() - a.registrationDate.getTime()));
        return filteredUsers.slice(0, 10);
      }),
    );
  }
}
