import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Injectable } from '@angular/core';
import { MatchEntity, UserEntity } from 'sevenfallen-core';
import { take } from 'rxjs/operators';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  pdfMake: any;
  constructor() {}

  async loadPdfMaker() {
    if (!this.pdfMake) {
      const pdfMakeModule = await import('pdfmake/build/pdfmake');
      const pdfFontsModule = await import('pdfmake/build/vfs_fonts');
      this.pdfMake = pdfMakeModule.default;
      this.pdfMake.vfs = pdfFontsModule.default.pdfMake.vfs;
    }
  }

  async generateMatchesPdf(matches: MatchEntity[]) {
    const rows = [];
    let i = 1;
    for (let match of matches) {
      const registration1 = await match.registration1$.pipe(take(1)).toPromise();
      const player1: UserEntity = await UserEntity.read<UserEntity>(registration1.userId).pipe(take(1)).toPromise();

      const registration2 = await match.registration2$.pipe(take(1)).toPromise();
      const player2: UserEntity = await UserEntity.read<UserEntity>(registration2.userId).pipe(take(1)).toPromise();

      rows.push([i, player1.pseudo, player2.pseudo]);
      i++;
    }
    await this.loadPdfMaker();
    const docDefinition = {
      content: [
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            headerRows: 1,
            widths: ['*', '*', '*'],

            body: [['#', 'Joueur 1', 'Joueur 2'], ...rows],
          },
        },
      ],
    };
    this.pdfMake.createPdf(docDefinition).open();
  }
}
