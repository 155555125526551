import { AbstractEntity, AbstractRestEntity, EntityProperty } from 'ng-fluxify';
import * as equal from 'fast-deep-equal';
import { Observable, race, throwError, timer } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';

export abstract class Abstract7fallenEntity<T extends number | string = number> extends AbstractRestEntity {
  static readonly expiredIds = new Set<any>();

  @EntityProperty({ type: Number, primary: true })
  public id: T;

  static setExpired(id: number) {
    this.expiredIds.add(id);
  }

  setExpired() {
    // @ts-ignore
    this.constructor.expiredIds.add(this.id);
  }

  static read<T extends AbstractEntity>(id: any): Observable<T> {
    return race(
      super.read<T>(id),
      timer(60000).pipe(switchMap((_) => throwError(new Error(`${this.prototype.constructor.name} read ${id} timeout`)))),
    ).pipe(distinctUntilChanged((a, b) => equal(a, b)));
  }

  static readAll<T extends AbstractEntity>(): Observable<T[]> {
    return race(
      super.readAll<T>(),
      timer(60000).pipe(switchMap((_) => throwError(new Error(`${this.prototype.constructor.name} read all timeout`)))),
    )
      .pipe
      // distinctUntilChanged((a, b) => a.length === b.length && JSON.stringify(a.map(i => i.sanitized)) === JSON.stringify(b.map(i => i.sanitized))),
      ();
  }
}
