import { Observable } from 'rxjs';
import { UserEntity } from '../../entities';
import { AuthProviderService } from './auth-provider.service';

export abstract class IAuthenticationService {
  abstract get isAuthenticated$(): Observable<boolean>;

  abstract get isAuthenticated(): boolean;

  abstract get token$(): Observable<string>;

  abstract get isReady$(): Observable<boolean>;

  abstract get isReady(): boolean;

  abstract get currentUser$(): Observable<UserEntity>;

  abstract get login$(): Observable<string>;

  abstract get login(): string;

  abstract initialize(): Promise<void>;

  abstract register(userInfo: UserInfo): Promise<void>;

  abstract signIn(authProvider: AuthProviderService): Promise<void>;

  abstract signOut(): Promise<void>;
}

export interface UserInfo {
  firstname: string;
  lastname: string;
  pseudo: string;
  discord: string;
  email: string;
  countryId: number;
}
