import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CoreModule } from 'sevenfallen-core';
import { UiModule } from 'sevenfallen-ui';

import * as Components from './components';
import * as Services from './services';

@NgModule({
  imports: [CommonModule, RouterModule, CoreModule, UiModule],
  declarations: [Components.TournamentCardComponent],
  exports: [Components.TournamentCardComponent],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [Services.QueryParamsService],
    };
  }
}
