export const environment = {
  production: true,
  appVersion: '1.0.6',
  apiURL: 'https://7fallen.keremaprod.com/api',
  firebaseConfig: {
    apiKey: 'AIzaSyDbv5TsuugeAkK53nriHUY39CTtucvCmeg',
    authDomain: 'fallen-13c8f.firebaseapp.com',
    projectId: 'fallen-13c8f',
    storageBucket: 'fallen-13c8f.appspot.com',
    messagingSenderId: '541009215148',
    appId: '1:541009215148:web:c3f6e6720b60f8e875e909',
  },
};
