import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from 'sevenfallen-core';
import {ReloadModalComponent} from './reload-modal.component';

@NgModule({
  declarations: [ReloadModalComponent],
  imports: [CommonModule, CoreModule],
})
export class ReloadModalModule {}
