import { RestEntityDescriptor, RestEntityDescriptorAttributes } from 'ng-fluxify';
import { Abstract7fallenEntity } from '../entities';

export class CustomRestEntityDescriptor<T extends Abstract7fallenEntity> extends RestEntityDescriptor<T> {
  expirationDetectionStrategy = (id: number) => {
    const expiredIds: Set<number> = this.class.expiredIds;
    return expiredIds.delete(id);
  };

  constructor(attributes: RestEntityDescriptorAttributes) {
    super(attributes);
  }
}
