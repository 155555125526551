import { Entity, EntityProperty, ManyToOne, OneToMany } from 'ng-fluxify';
import { forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoryEntity } from './category.entity';
import { CountryEntity } from './country.entity';
import { OrganizerEntity } from './organizer.entity';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';
import { RegistrationEntity } from './registration.entity';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'Tournament',
    route: '/tournaments',
  }),
)
export class TournamentEntity extends Abstract7fallenEntity {
  @EntityProperty({ type: String })
  public name: string;

  @EntityProperty({ type: Number })
  public categoryId: number;

  @ManyToOne({ entity: forwardRef(() => CategoryEntity), foreignKey: 'categoryId' })
  public category$: Observable<CategoryEntity>;

  @EntityProperty({ type: String })
  public image: string;

  @EntityProperty({ type: Date })
  public creationDate: Date;

  @EntityProperty({ type: Date })
  public startDate: Date;

  @EntityProperty({ type: Date })
  public endDate: Date;

  @EntityProperty({ type: String })
  public description: string;

  @EntityProperty({ type: String })
  public prize: string;

  @EntityProperty({ type: String })
  public rulesUrl: string;

  @EntityProperty({ type: Date })
  public registrationOpenDate: Date;

  @EntityProperty({ type: Date })
  public registrationCloseDate: Date;

  @EntityProperty({ type: Number })
  public maxRegistrations: number;

  @EntityProperty({ type: Boolean })
  public isRegistrationValidated: boolean;

  @EntityProperty({ type: Boolean })
  public isCheckInActive: boolean;

  @EntityProperty({ type: Number })
  public countryId: number;

  @ManyToOne({ entity: forwardRef(() => CountryEntity), foreignKey: 'countryId' })
  public country$: Observable<CountryEntity>;

  @EntityProperty({ type: String })
  public city: string;

  @EntityProperty({ type: Boolean })
  public isOnline: boolean;

  @EntityProperty({ type: Number })
  public status: number;

  @EntityProperty({ type: Boolean })
  public isOffStats: boolean;

  @EntityProperty({ type: Boolean })
  public isOfficial: boolean;

  @EntityProperty({ type: Number })
  public ratio: number;

  @OneToMany({ entity: forwardRef(() => OrganizerEntity), foreignKey: 'tournamentId' })
  public organizers$: Observable<OrganizerEntity[]>;

  @OneToMany({ entity: forwardRef(() => RegistrationEntity), foreignKey: 'tournamentId' })
  public registrations$: Observable<RegistrationEntity[]>;
}

export enum TournamentsStatusEnum {
  DRAFT = 0,
  OPEN = 1,
  FULL = 2,
  ONGOING = 3,
  DONE = 4,
}
