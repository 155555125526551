import { Entity, EntityProperty } from 'ng-fluxify';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'CategoryGroup',
    route: '/category_groups',
  }),
)
export class CategoryGroupEntity extends Abstract7fallenEntity {
  @EntityProperty({ type: String })
  public label: string;

  @EntityProperty({ type: Boolean })
  public active: boolean;
}
