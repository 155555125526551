import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AutocompleteItemComponent } from '../autocomplete-item/autocomplete-item.component';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent<T> implements ControlValueAccessor, AfterContentInit, OnDestroy {
  @Input() searchFn: (text: string, items: T[]) => T[];
  @Input() displayFn: (item: T) => string;
  @Input() placeholder: string;
  @Input() maxItems = 10;

  @ViewChild('input', { static: true, read: ElementRef }) input: ElementRef<HTMLInputElement>;
  @ContentChildren(AutocompleteItemComponent) autocompleteItems: QueryList<AutocompleteItemComponent<T>>;

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  get items(): T[] {
    return this.autocompleteItems ? this.autocompleteItems.map((autocompleteItem) => autocompleteItem.item) : [];
  }

  get isOpened(): boolean {
    return !!this.displayedResults;
  }

  get isEmpty(): boolean {
    return this.displayedResults && this.displayedResults.length === 0;
  }

  displayedResults: AutocompleteItemComponent<T>[];
  preSelectedItem: AutocompleteItemComponent<T>;
  selectedItem?: T;
  isDisabled = false;
  lastSearch: string;
  trackByItem = (_, item: AutocompleteItemComponent<T>) => item.item;

  private onChange?: (_: any) => void;
  private onTouched?: (_: any) => void;

  private timeout: number;
  private subscriptions = new Subscription();

  constructor(private changeDetectorRef: ChangeDetectorRef, private elementRef: ElementRef) {}

  ngAfterContentInit() {
    let autocompleteSubscriptions = new Subscription();
    this.subscribeToItems(autocompleteSubscriptions);

    this.subscriptions.add(
      this.autocompleteItems.changes.subscribe(_ => this.subscribeToItems(autocompleteSubscriptions)),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (_: any) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: T): void {
    this.selectedItem = value;

    if (!this.displayFn) {
      throw new Error('No display function provided');
    }

    this.input.nativeElement.value = this.selectedItem ? this.displayFn(this.selectedItem) : null;
    delete this.lastSearch;
  }

  search(text: string, buffer = true): void {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(
      () => {
        if (!this.searchFn) {
          throw new Error('No search function provided');
        }

        let filteredItems = this.items;
        this.lastSearch = text?.trim();
        if (this.lastSearch && this.lastSearch.length > 0) {
          filteredItems = this.searchFn(this.lastSearch, filteredItems);
        }

        this.displayedResults = this.autocompleteItems.filter((autocompleteItem) => filteredItems.includes(autocompleteItem.item));

        this.changeDetectorRef.detectChanges();
      },
      buffer ? 300 : 0,
    );
  }

  onArrowDown(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.preSelectedItem) {
      this.preSelectedItem.preSelected = false;
      const index = this.displayedResults.indexOf(this.preSelectedItem) + 1;
      this.preSelectedItem = this.displayedResults[index < this.displayedResults.length ? index : 0];
    } else {
      this.preSelectedItem = this.displayedResults[0];
    }

    this.preSelectedItem.preSelected = true;
  }

  onArrowUp(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.preSelectedItem) {
      this.preSelectedItem.preSelected = false;
      const index = this.displayedResults.indexOf(this.preSelectedItem);
      this.preSelectedItem = this.displayedResults[(index > 0 ? index : this.displayedResults.length) - 1];
    } else {
      this.preSelectedItem = this.displayedResults[this.displayedResults.length - 1];
    }

    this.preSelectedItem.preSelected = true;
  }

  onEnter(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    if (this.preSelectedItem) {
      clearTimeout(this.timeout);
      this.selectItem(this.preSelectedItem.item);
    }
  }

  private subscribeToItems(autocompleteSubscriptions: Subscription) {
    autocompleteSubscriptions.unsubscribe();
    autocompleteSubscriptions = new Subscription();
    this.subscriptions.add(autocompleteSubscriptions);

    this.autocompleteItems.forEach(autocompleteItem =>
      autocompleteSubscriptions.add(
        autocompleteItem.selected.subscribe(() => this.selectItem(autocompleteItem.item)),
      ),
    );
  }

  private selectItem(item: T): void {
    this.writeValue(item);
    this.close();

    this.onChange && this.onChange(item);
    this.onTouched && this.onTouched(item);
  }

  close(): void {
    delete this.displayedResults;
  }
}
