import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'range',
})
export class RangePipe implements PipeTransform {
  transform(val: number): number[] {
    return new Array(val).fill(0).map((v, i) => i + 1);
  }
}
