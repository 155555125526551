import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { TranslateModule } from '@ngx-translate/core';
import { SnotifyModule } from 'ng-snotify';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from 'firebase/app';

import * as Guards from './guards';
import * as Interceptors from './interceptors';
import * as Pipes from './pipes';
import * as ServicesImpl from './services/impl';
import * as ServicesInterface from './services/interfaces';
import { FACEBOOK_AUTH_PROVIDER, GOOGLE_AUTH_PROVIDER } from './tokens';
import { CoreConfig } from './core.config';

export const CORE_CONFIG = new InjectionToken<CoreConfig>('Core config');

@NgModule({
  imports: [CommonModule, RouterModule, AngularFireModule, ReactiveFormsModule, TranslateModule, SnotifyModule, NgbModule],
  declarations: [
    Pipes.AlreadyRegisteredPipe,
    Pipes.Get10MostRecentUsersPipe,
    Pipes.IntNoZeroPipe,
    Pipes.IsDateSuperiorPipe,
    Pipes.RangePipe,
    Pipes.StateToLabelPipe,
    Pipes.MatchNumberToLabelPipe,
    Pipes.GetLastScore,
    Pipes.AvatarPipe,
    Pipes.RegistrationNotCompletePipe,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    SnotifyModule,
    NgbModule,
    Pipes.AlreadyRegisteredPipe,
    Pipes.Get10MostRecentUsersPipe,
    Pipes.IntNoZeroPipe,
    Pipes.IsDateSuperiorPipe,
    Pipes.RangePipe,
    Pipes.StateToLabelPipe,
    Pipes.MatchNumberToLabelPipe,
    Pipes.GetLastScore,
    Pipes.AvatarPipe,
    Pipes.RegistrationNotCompletePipe,
  ],
})
export class CoreModule {
  static forRoot(config: CoreConfig): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Interceptors.ApiInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: Interceptors.TokenInterceptor,
          multi: true,
        },
        { provide: CORE_CONFIG, useValue: config },
        Guards.AppReadyGuard,
        Guards.GuestOnlyGuard,
        Guards.UserOnlyGuard,
        Guards.RoleGuard,
        { provide: ServicesInterface.IAuthenticationService, useClass: ServicesImpl.AuthenticationService },
        {
          provide: GOOGLE_AUTH_PROVIDER,
          useFactory: (angularFireAuth: AngularFireAuth) =>
            new ServicesImpl.RedirectAuthProviderService(angularFireAuth, new firebase.auth.GoogleAuthProvider()),
          deps: [AngularFireAuth],
        },
        {
          provide: FACEBOOK_AUTH_PROVIDER,
          useFactory: (angularFireAuth: AngularFireAuth) =>
            new ServicesImpl.RedirectAuthProviderService(angularFireAuth, new firebase.auth.FacebookAuthProvider()),
          deps: [AngularFireAuth],
        },
        { provide: ServicesInterface.ITournamentService, useClass: ServicesImpl.TournamentService },
        Pipes.AvatarPipe,
      ],
    };
  }
}
