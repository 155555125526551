import { Pipe, PipeTransform } from '@angular/core';
import { RegistrationEntity } from 'sevenfallen-core';
import { ScoreEntity } from '../entities/score.entity';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'getLastScore',
})
export class GetLastScore implements PipeTransform {
  transform(registration: RegistrationEntity): Observable<ScoreEntity> {
    return ScoreEntity.readAll<ScoreEntity>().pipe(
      map((scores) =>
        scores
          .filter((score) => score.tournamentId === registration.tournamentId && score.registrationId === registration.id)
          .reduce((prev, current) => (prev.round > current.round ? prev : current)),
      ),
    );
  }
}
