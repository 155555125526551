import { Pipe, PipeTransform } from '@angular/core';
import { RegistrationStateEnum } from '../entities';

@Pipe({
  name: 'stateToLabel',
})
export class StateToLabelPipe implements PipeTransform {
  transform(state: RegistrationStateEnum): string {
    switch (state) {
      case RegistrationStateEnum.CHECKED_IN:
        return 'utils.registration-status.checked-in';
      case RegistrationStateEnum.VALIDATED:
        return 'utils.registration-status.validated';
      case RegistrationStateEnum.WAITING:
        return 'utils.registration-status.waiting';
      default:
        return '';
    }
  }
}
