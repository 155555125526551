import { Injectable } from '@angular/core';
import Fuse from 'fuse.js';

@Injectable()
export class TextSearchService<T> {
  private fuse: Fuse<T>;
  private defaultOptions: Fuse.IFuseOptions<T> = {
    threshold: 0.3,
    distance: 200,
  };

  init(items: T[], options: Fuse.IFuseOptions<T>) {
    this.fuse = new Fuse(items, Object.assign({}, this.defaultOptions, options));
  }

  search(text: string): T[] {
    return this.fuse.search(text).map((result) => result.item);
  }
}
