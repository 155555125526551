import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable()
export class AvatarService {
  private loadingAvatars = new Map<string, { promise: Promise<string>; progressSubject$: Subject<number> }>();
  private avatarMap = new Map<string, string>();

  async loadAvatar(url: string, onprogress: (progress: number) => void): Promise<string> {
    if (this.avatarMap.has(url)) {
      return this.avatarMap.get(url);
    }

    if (!this.loadingAvatars.has(url)) {
      const progressSubject$ = new ReplaySubject<number>(1);
      const promise = new Promise<string>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        let notifiedNotComputable = false;

        xhr.open('GET', url, true);
        xhr.responseType = 'blob';

        xhr.onprogress = function (ev) {
          if (ev.lengthComputable) {
            progressSubject$.next(parseInt(String((ev.loaded / ev.total) * 100)));
          } else {
            if (!notifiedNotComputable) {
              notifiedNotComputable = true;
              progressSubject$.next(-1);
            }
          }
        };

        xhr.onloadend = function () {
          if (!xhr.status.toString().match(/^2/)) {
            reject(xhr);
          } else {
            if (!notifiedNotComputable) {
              progressSubject$.next(100);
              progressSubject$.complete();
            }

            resolve(window.URL.createObjectURL(this.response));
          }
        };

        xhr.send();
      });

      this.loadingAvatars.set(url, { promise, progressSubject$ });
    }

    const { promise, progressSubject$ } = this.loadingAvatars.get(url);
    const sub = progressSubject$.subscribe((progress) => onprogress(progress));

    const result = await promise;
    this.avatarMap.set(url, result);

    sub.unsubscribe();

    return result;
  }
}
