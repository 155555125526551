import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAuthenticationService } from '../services/interfaces';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: IAuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    if (route.data.roleGuardArray) {
      const authorizedArray: number[] = route.data.roleGuardArray;
      // check des roles
      return this.authenticationService.currentUser$.pipe(
        map((u) => {
          if (!authorizedArray.includes(u.role)) {
            return this.router.createUrlTree(['/home']);
          }

          return true;
        }),
      );
    }
    return of(true);
  }
}
