import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paginate',
})
export class PaginatePipe implements PipeTransform {
  transform<T>(value: T[], itemsPerPage: number, currentPage: number = 1): T[] {
    if (!itemsPerPage) {
      return value;
    }

    return value.slice(itemsPerPage * (currentPage - 1), itemsPerPage * currentPage);
  }
}
