import { Pipe, PipeTransform } from '@angular/core';

export type Comparator<T> = (a: T, b: T) => number;

@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  transform<T>(values: T[], comparator: Comparator<T>, reverse = false): T[] {
    const sortedValues = values?.sort(comparator);

    if (reverse) {
      sortedValues.reverse();
    }

    return sortedValues;
  }
}
