import {BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject} from 'rxjs';
import {distinctUntilChanged, map, switchMap, take, tap} from 'rxjs/operators';
import * as equal from 'fast-deep-equal';
import {ITournamentService, MatchState} from '../interfaces';
import {
  CancelRoundEntity,
  GenerateScoreEntity,
  MatchEntity,
  NewRoundEntity,
  OrganizerEntity,
  RegistrationEntity,
  TournamentEntity,
  TournamentsStatusEnum,
  UserEntity,
} from '../../entities';
import {SeedHelper} from '../../helpers/seed.helper';
import {ScoreEntity} from '../../entities/score.entity';
import {UserRolesEnum} from 'sevenfallen-core';

export class TournamentService implements ITournamentService {
  readonly tournament$: Observable<TournamentEntity>;
  readonly registrations$: Observable<RegistrationEntity[]>;
  readonly organizers$: Observable<OrganizerEntity[]>;
  readonly scores$: Observable<ScoreEntity[]>;
  readonly usersNotOrganizer$: Observable<UserEntity[]>;
  readonly usersNotIn$: Observable<UserEntity[]>;
  readonly sortedPlayers$: Observable<RegistrationEntity[]>;
  readonly roundMaxCount$: Observable<number>;
  readonly allScores$: Observable<Map<number, ScoreEntity[]>>;
  readonly canCreateNewRound$: Observable<boolean>;
  readonly rounds$: Observable<Map<number, MatchEntity[]>>;
  readonly treeMatches$: Observable<MatchEntity[]>;
  readonly treeMatchesMap$: Observable<Map<number, MatchEntity[]>>;
  readonly roundCount$: Observable<number>;
  readonly currentRound$: Observable<number>;
  readonly selectedRound$: Observable<number>;
  readonly isCurrentRound$: Observable<boolean>;
  readonly areCurrentRoundScoresEntered$: Observable<boolean>;
  readonly canCancelLastRound$: Observable<boolean>;
  readonly tournamentIsOngoing$: Observable<boolean>;
  readonly availablePowersOfTwo$: Observable<number[]>;
  readonly canDrop$: Observable<Map<string, boolean>>;
  readonly canEdit$: Observable<boolean>;
  readonly canUndrop$: Observable<Map<string, boolean>>;
  readonly playersDropped$: Observable<Map<string, boolean>>;
  readonly isPending$: Observable<Map<number, boolean>>;

  private tournamentIdSubject$ = new ReplaySubject<number>(1);
  private selectedRoundSubject$ = new BehaviorSubject<number>(0);

  private playerMatchesWinDiffMap = new Map<string, Observable<number | false>>();

  public autoScoreCalculation: BehaviorSubject<boolean>;
  public isLoading = new Subject<boolean>();

  constructor() {
    this.tournament$ = this.tournamentIdSubject$.pipe(switchMap((tournamentId) => TournamentEntity.read<TournamentEntity>(tournamentId)));

    this.scores$ = ScoreEntity.readAll<ScoreEntity>();

    this.registrations$ = combineLatest([RegistrationEntity.readAll<RegistrationEntity>(), this.tournament$]).pipe(
      map(([registrations, tournament]) => registrations.filter((registration) => registration.tournamentId === tournament.id)),
    );
    this.organizers$ = combineLatest([OrganizerEntity.readAll<OrganizerEntity>(), this.tournament$]).pipe(
      map(([organizers, tournament]) => organizers.filter((organizer) => organizer.tournamentId === tournament.id)),
    );

    this.roundMaxCount$ = this.registrations$.pipe(
      map((registrations) => TournamentService.lowerPowerOfTwo(registrations.length)),
      distinctUntilChanged(),
    );

    this.rounds$ = combineLatest([this.registrations$, MatchEntity.readAll<MatchEntity>(), this.roundMaxCount$]).pipe(
      map(([registrations, matchs, roundMaxCount]) => {
        const registrationsIds = new Set(registrations.map((registration) => registration.id));

        return matchs
          .filter(
            (match) =>
              (registrationsIds.has(match.registration1Id) || registrationsIds.has(match.registration2Id)) && match.round <= roundMaxCount,
          )
          .reduce((acc, match) => {
            acc.set(match.round, [...(acc.get(match.round) ?? []), match]);
            return acc;
          }, new Map<number, MatchEntity[]>());
      }),
    );

    this.roundCount$ = this.rounds$.pipe(
      map((rounds) => rounds.size),
      distinctUntilChanged(),
    );

    this.tournamentIsOngoing$ = this.roundCount$.pipe(
      map((roundCount) => roundCount >= 1),
      distinctUntilChanged(),
    );

    this.currentRound$ = this.roundCount$.pipe(
      map((roundCount) => roundCount - 1),
      distinctUntilChanged(),
    );

    this.selectedRound$ = this.selectedRoundSubject$.asObservable().pipe(distinctUntilChanged());

    this.isCurrentRound$ = combineLatest([this.currentRound$, this.selectedRound$]).pipe(
      map(([currentRound, selectedRound]) => currentRound === selectedRound),
      distinctUntilChanged(),
    );

    this.areCurrentRoundScoresEntered$ = combineLatest([this.rounds$, this.currentRound$]).pipe(
      map(
        ([rounds, currentRound]) =>
          currentRound >= 0 && rounds.get(currentRound) && rounds.get(currentRound).every((match) => match.state !== MatchState.Pending),
      ),
      distinctUntilChanged(),
    );

    this.usersNotIn$ = combineLatest([UserEntity.readAll<UserEntity>(), this.registrations$]).pipe(
      map(([users, registrations]) => {
        const userInIds = new Set(registrations.map((registration) => registration.userId));
        return users.filter((user) => !userInIds.has(user.id));
      }),
    );

    const potentialOrganizers = UserEntity.readAll<UserEntity>().pipe(
      map((users) => users.filter((u) => u.role && u.role !== UserRolesEnum.Player)),
    );

    this.usersNotOrganizer$ = combineLatest([potentialOrganizers, this.organizers$]).pipe(
      tap(console.log),
      map(([users, organizers]) => {
        const userInIds = new Set(organizers.map((registration) => registration.userId));
        return users.filter((user) => !userInIds.has(user.id));
      }),
    );

    this.sortedPlayers$ = this.registrations$.pipe(map((registrations) => registrations.sort((a, b) => a.userId.localeCompare(b.userId))));

    this.allScores$ = combineLatest([this.scores$, this.roundMaxCount$, this.rounds$, this.tournament$]).pipe(
      map(([scores, roundMaxCount, rounds, tournament]) => {
        const allScores = new Map<number, ScoreEntity[]>();

        for (let round = 0; round < roundMaxCount; round++) {
          allScores.set(
            round,
            scores.filter((score: ScoreEntity) => score.round === round && score.tournamentId === tournament.id),
          );

          allScores.get(round).sort((score1: ScoreEntity, score2: ScoreEntity) => {
            // Rank
            if (score1.rank != score2.rank) {
              return score1.rank > score2.rank ? 1 : -1;
            }
            return 0;
          });
        }

        return allScores;
      }),
    );

    this.canDrop$ = combineLatest([
      this.registrations$,
      this.roundMaxCount$,
      this.rounds$,
      this.roundCount$,
      this.selectedRound$,
      this.currentRound$,
      this.isCurrentRound$,
    ]).pipe(
      map(
        ([registrations, roundMaxCount, rounds, roundCount, selectedRound, currentRound, isCurrentRound]: [
          RegistrationEntity[],
          number,
          Map<number, MatchEntity[]>,
          number,
          number,
          number,
          boolean,
        ]) => {
          return new Map(
            registrations.map((player): [string, boolean] => {
              if (player.drop !== null || roundCount === 0 || selectedRound != currentRound) {
                return [player.userId, false];
              }

              if (currentRound >= 0 && isCurrentRound) {
                const match = this.findCurrentMatch(player.id, currentRound, rounds);
                if (match) {
                  return [player.userId, match.state !== MatchState.Pending];
                }
              }

              return [player.userId, false];
            }),
          );
        },
      ),
    );

    this.canEdit$ = combineLatest([this.rounds$, this.selectedRound$, this.isCurrentRound$, this.roundCount$]).pipe(
      map(([rounds, selectedRound, isCurrentRound, roundCount]: [Map<number, MatchEntity[]>, number, boolean, number]) => {
        if (!isCurrentRound || roundCount === 0) {
          return false;
        }
        if (rounds.get(selectedRound) && rounds.get(selectedRound).some((match) => match.state === MatchState.Validated)) {
          return false;
        }
        return true;
      }),
    );

    this.canUndrop$ = combineLatest([
      this.registrations$,
      this.roundMaxCount$,
      this.rounds$,
      this.roundCount$,
      this.selectedRound$,
      this.currentRound$,
      this.isCurrentRound$,
    ]).pipe(
      map(
        ([registrations, roundMaxCount, rounds, roundCount, selectedRound, currentRound, isCurrentRound]: [
          RegistrationEntity[],
          number,
          Map<number, MatchEntity[]>,
          number,
          number,
          number,
          boolean,
          boolean,
        ]) => {
          return new Map(
            registrations.map((player): [string, boolean] => {
              if (player.drop !== currentRound || roundCount === 0 || roundCount === roundMaxCount || selectedRound != currentRound) {
                return [player.userId, false];
              }

              if (currentRound >= 0 && isCurrentRound) {
                const match = this.findCurrentMatch(player.id, currentRound, rounds);
                if (match) {
                  return [player.userId, match.state !== MatchState.Pending];
                }
              }

              return [player.userId, false];
            }),
          );
        },
      ),
    );

    this.playersDropped$ = combineLatest([this.registrations$, this.selectedRound$]).pipe(
      map(([registrations, selectedRound]) => {
        return new Map(
          registrations.map((player): [string, boolean] => [player.userId, player.drop !== null && player.drop <= selectedRound]),
        );
      }),
    );

    this.isPending$ = combineLatest([this.registrations$, this.isCurrentRound$, this.currentRound$, this.rounds$]).pipe(
      map(
        ([registrations, isCurrentRound, currentRound, rounds]) =>
          new Map(
            registrations.map((registration) => [
              registration.id,
              isCurrentRound && this.findCurrentMatch(registration.id, currentRound, rounds)?.state === MatchState.Pending,
            ]),
          ),
      ),
    );

    this.canCreateNewRound$ = combineLatest([
      this.registrations$,
      this.roundMaxCount$,
      this.roundCount$,
      this.areCurrentRoundScoresEntered$,
    ]).pipe(
      map(([registrations, roundMaxCount, roundCount, areCurrentRoundScoresEntered]) => {
        if (roundCount < roundMaxCount) {
          // There is a next round
          if (roundCount === 0 || areCurrentRoundScoresEntered) {
            // There is no rounds, or the scores are entered
            let playersReadyToNewRound = registrations.filter((p) => !p.drop);
            if (playersReadyToNewRound.length >= 2) {
              // There is at least 2 players left (no dropped)
              return true;
            }
          }
        }

        return false;
      }),
      distinctUntilChanged(),
    );

    this.canCancelLastRound$ = combineLatest([this.rounds$, this.currentRound$]).pipe(
      map(
        ([rounds, currentRound]) =>
          rounds.get(currentRound) && !rounds.get(currentRound).some((match) => match.state !== MatchState.Pending),
      ),
      distinctUntilChanged(),
    );

    this.availablePowersOfTwo$ = this.registrations$.pipe(
      map((registrations) => {
        const availablePowerOfTwo: number[] = [];
        let exponant = 1;
        let powerOfTwo = 2 ** exponant;
        if (registrations.length >= 2) {
          while (registrations.length >= powerOfTwo) {
            availablePowerOfTwo.push(powerOfTwo);
            exponant++;
            powerOfTwo = 2 ** exponant;
          }
        }

        return availablePowerOfTwo;
      }),
      distinctUntilChanged((a, b) => a.length === b.length && equal(a, b)),
    );

    this.treeMatches$ = combineLatest([this.registrations$, MatchEntity.readAll<MatchEntity>(), this.roundMaxCount$]).pipe(
      map(([registrations, matchs, roundMaxCount]) => {
        const registrationsIds = new Set(registrations.map((registration) => registration.id));
        return matchs.filter(
          (match) =>
            (registrationsIds.has(match.registration1Id) || registrationsIds.has(match.registration2Id)) && match.round > roundMaxCount,
        );
      }),
    );

    this.treeMatchesMap$ = this.treeMatches$.pipe(
      map((matches) => {
        return matches.reduce((acc, match) => {
          acc.set(match.round, [...(acc.get(match.round) ?? []), match]);
          return acc;
        }, new Map<number, MatchEntity[]>());
      }),
    );
  }

  init(tournamentId: number): void {
    this.tournamentIdSubject$.next(tournamentId);
    this.selectedRoundSubject$.next(0);
    this.playerMatchesWinDiffMap.clear();
    this.generateAndGetScores();
  }

  selectRound(round: number): void {
    this.selectedRoundSubject$.next(round);
  }

  playerMatchesWinDiff(registrationId1: number, registrationId2: number, roundIndexMax: number | undefined): Observable<number | false> {
    const key = `${registrationId1}-${registrationId2}-${roundIndexMax}`;
    const keyReverse = `${registrationId2}-${registrationId1}-${roundIndexMax}`;

    if (!this.playerMatchesWinDiffMap.has(key) && !this.playerMatchesWinDiffMap.has(keyReverse)) {
      this.playerMatchesWinDiffMap.set(
        key,
        this.rounds$.pipe(
          map((rounds) => {
            if (
              !Number.isInteger(registrationId1) ||
              !Number.isInteger(registrationId2) ||
              registrationId1 === -1 ||
              registrationId2 === -1
            ) {
              return false;
            }

            const playerMatches = this.playerMatches(rounds, registrationId1, registrationId2, roundIndexMax);
            if (playerMatches.length == 0) {
              return false;
            }

            if (playerMatches.every((match) => match.state !== MatchState.Validated)) {
              return false;
            }

            return playerMatches.reduce((acc, match) => {
              if (
                (match.registration1Id === registrationId1 && match.registration1Point > match.registration2Point) ||
                (match.registration2Id === registrationId1 && match.registration2Point > match.registration1Point)
              ) {
                acc++;
              } else if (
                (match.registration1Id === registrationId2 && match.registration1Point > match.registration2Point) ||
                (match.registration2Id === registrationId2 && match.registration2Point > match.registration1Point)
              ) {
                acc--;
              }
              return acc;
            }, 0);
          }),
        ),
      );
    }

    if (this.playerMatchesWinDiffMap.has(key)) {
      return this.playerMatchesWinDiffMap.get(key);
    }

    return this.playerMatchesWinDiffMap.get(keyReverse).pipe(map((value) => (value === false ? false : value * -1)));
  }

  async newRound(): Promise<void> {
    const roundMaxCount = await this.roundMaxCount$.pipe(take(1)).toPromise();
    const roundCount = await this.roundCount$.pipe(take(1)).toPromise();
    const currentRound = await this.currentRound$.pipe(take(1)).toPromise();
    const tournament = await this.tournament$.pipe(take(1)).toPromise();

    this.isLoading.next(true);
    await this.generateAndGetScores();

    const newRound = new NewRoundEntity();
    newRound.roundCount = roundCount;
    newRound.tournamentId = tournament.id;
    newRound.roundMaxCount = roundMaxCount;
    newRound.currentRound = currentRound;
    await newRound.save();
    MatchEntity.entityManager.reset();
    await MatchEntity.readAll();
    TournamentEntity.entityManager.reset();
    await TournamentEntity.readAll();
    this.selectRound(roundCount);
    this.isLoading.next(false);
  }

  async cancelTreePhase(): Promise<void> {
    const treeMatchMap = await this.treeMatchesMap$.pipe(take(1)).toPromise();
    const matches = Array.from(treeMatchMap.values());
    const matchesToRemove = matches.pop();
    if(matchesToRemove && matchesToRemove.length) {
      for(let match of matchesToRemove) {
        await match.delete();
      }

    }
    const matchesToInvalidate = matches.pop();
    if(matchesToInvalidate && matchesToInvalidate.length) {
      for(let match of matchesToInvalidate) {
        match.state = MatchState.Pending;
        await match.save();
      }
    }
    MatchEntity.entityManager.reset();
    await MatchEntity.readAll();
  }

  async cancelRound(): Promise<void> {
    const currentRound = await this.currentRound$.pipe(take(1)).toPromise();
    const tournament = await this.tournament$.pipe(take(1)).toPromise();

    this.isLoading.next(true);

    const cancelRound = new CancelRoundEntity();
    cancelRound.tournamentId = tournament.id;
    cancelRound.currentRound = currentRound;

    await cancelRound.save();
    MatchEntity.entityManager.reset();
    await MatchEntity.readAll();

    ScoreEntity.entityManager.reset();
    await ScoreEntity.readAll();

    TournamentEntity.entityManager.reset();
    await TournamentEntity.readAll();

    if (currentRound > 0) {
      this.selectRound(currentRound - 1);
    }
    this.isLoading.next(false);
  }

  async generateAndGetScores(): Promise<void> {
    this.isLoading.next(true);
    const roundMaxCount = await this.roundMaxCount$.pipe(take(1)).toPromise();
    const roundCount = await this.roundCount$.pipe(take(1)).toPromise();
    const tournament = await this.tournament$.pipe(take(1)).toPromise();

    const generateScore = new GenerateScoreEntity();
    generateScore.roundCount = roundCount;
    generateScore.tournamentId = tournament.id;
    generateScore.roundMaxCount = roundMaxCount;
    await generateScore.save();

    ScoreEntity.entityManager.reset();
    ScoreEntity.readAll<ScoreEntity>();
    this.isLoading.next(false);
  }

  async newTree(powerOfTwo: number): Promise<void> {
    const tournament = await this.tournament$.pipe(take(1)).toPromise();
    const maxRound = await this.roundMaxCount$.pipe(take(1)).toPromise();
    const roundCount = await this.roundCount$.pipe(take(1)).toPromise();
    const allScores = await this.allScores$.pipe(take(1)).toPromise();
    const rankedPlayer = allScores.get(roundCount - 1);

    // Generate first tour
    const seeds = SeedHelper.getSeed(powerOfTwo);
    const round = maxRound + 1;

    await Promise.all(
      seeds.map((seed: { p1: number; p2: number }) => {
        const match = new MatchEntity();
        match.registration1Id = rankedPlayer[seed.p1 - 1].registrationId;
        match.registration2Id = rankedPlayer[seed.p2 - 1].registrationId;
        match.registration1Point = 0;
        match.registration2Point = 0;
        match.round = round;
        match.state = MatchState.Pending;
        match.tournamentId = tournament.id;

        match.save();
      }),
    );
  }

  async validateMatchesPair(
    matchId1: number,
    match1registration1Points: number,
    match1registration2Points: number,
    matchId2: number,
    match2registration1Points: number,
    match2registration2Points: number,
  ): Promise<void> {
    await this.validateMatch(matchId1, match1registration1Points, match1registration2Points, false, null, null, false, true);
    await this.validateMatch(matchId2, match2registration1Points, match2registration2Points, false, null, null, false, true);

    const tournament = await this.tournament$.pipe(take(1)).toPromise();
    const match1 = await MatchEntity.read<MatchEntity>(matchId1).pipe(take(1)).toPromise();
    const match2 = await MatchEntity.read<MatchEntity>(matchId2).pipe(take(1)).toPromise();

    const registration1Id = match1.registration1Point > match1.registration2Point ? match1.registration1Id : match1.registration2Id;
    const registration2Id = match2.registration1Point > match2.registration2Point ? match2.registration1Id : match2.registration2Id;
    const round = match1.round + 1;

    const match = new MatchEntity();
    match.registration1Id = registration1Id;
    match.registration2Id = registration2Id;
    match.registration1Point = 0;
    match.registration2Point = 0;
    match.round = round;
    match.state = MatchState.Pending;
    match.tournamentId = tournament.id;

    await match.save();
  }

  async validateMatch(
    matchId: number,
    registration1Points: number,
    registration2Points: number,
    editMode = false,
    registration1Id?: number,
    registration2Id?: number,
    invalidate = false,
    treeMode = false,
    lastMatch = false,
    temporary = false,
  ): Promise<void> {
    const match = await MatchEntity.read<MatchEntity>(matchId).pipe(take(1)).toPromise();
    const tournament = await this.tournament$.pipe(take(1)).toPromise();

    if (!editMode) {
      match.registration1Point = registration1Points;
      match.registration2Point = registration2Points;
      if(!temporary) {
        match.state = invalidate ? MatchState.Pending : MatchState.Validated;
      }
    } else {
      match.registration1Id = registration1Id;
      match.registration2Id = registration2Id;
    }

    await match.save();

    if (this.autoScoreCalculation.value && !treeMode && !temporary) {
      await this.generateAndGetScores();
    }

    if (treeMode) {
      const totalMatchInRound = await MatchEntity.readAll<MatchEntity>()
        .pipe(
          map((matches) => {
            return matches.filter((m) => m.tournamentId === tournament.id && m.round === match.round).length;
          }),
          take(1),
        )
        .toPromise();
      const score = new ScoreEntity();
      score.registrationId = match.registration1Point < match.registration2Point ? match.registration1Id : match.registration2Id;
      score.tournamentId = tournament.id;
      score.round = match.round;
      score.rank = totalMatchInRound * 2;
      score.save();
    }

    if (lastMatch) {
      const score = new ScoreEntity();
      score.registrationId = match.registration1Point > match.registration2Point ? match.registration1Id : match.registration2Id;
      score.tournamentId = tournament.id;
      score.round = match.round;
      score.rank = 1;
      score.save();
    }
  }

  async toggleDropPlayer(userId: string): Promise<void> {
    const registrations = await this.registrations$.pipe(take(1)).toPromise();
    const currentRound = await this.currentRound$.pipe(take(1)).toPromise();

    let player = registrations.find((reg) => reg.userId === userId);

    if (player === null) {
      throw Error("Can't find player");
    }

    if (player.drop !== currentRound) {
      player.drop = currentRound;
    } else {
      player.drop = null;
    }

    await player.save();
    this.generateAndGetScores();
  }

  private playerMatches(
    rounds: Map<number, MatchEntity[]>,
    registrationId1?: number,
    registrationId2?: number,
    roundIndexMax?: number,
  ): MatchEntity[] {
    if (registrationId1 === -1) {
      return [];
    }

    if (!Number.isInteger(registrationId2)) {
      registrationId2 = -1;
    }

    roundIndexMax = roundIndexMax >= 0 ? roundIndexMax : +Infinity;

    return [...rounds.entries()].reduce((acc, [currentIndex, round]) => {
      if (currentIndex <= roundIndexMax) {
        const match = round.find(
          (match) =>
            (match.registration1Id === registrationId1 && (registrationId2 === -1 || match.registration2Id === registrationId2)) ||
            (match.registration2Id === registrationId1 && (registrationId2 === -1 || match.registration1Id === registrationId2)),
        );

        if (match) {
          acc.push(match);
        }
      }
      return acc;
    }, []);
  }

  public async closeTournament(tournament: TournamentEntity = null): Promise<void> {
    if (!tournament) {
      tournament = await this.tournament$.pipe(take(1)).toPromise();
    }
    tournament.status = TournamentsStatusEnum.DONE;
    await tournament.save();
  }

  public async deleteTournament(tournament: TournamentEntity = null): Promise<void> {
    if (!tournament) {
      tournament = await this.tournament$.pipe(take(1)).toPromise();
    }
    await tournament.delete();

    await RegistrationEntity.entityManager.reset();
    await RegistrationEntity.readAll();

    await ScoreEntity.entityManager.reset();
    await ScoreEntity.readAll();

    await MatchEntity.entityManager.reset();
    await MatchEntity.readAll();
  }

  private findCurrentMatch(registrationId: number, currentRound: number, rounds: Map<number, MatchEntity[]>): MatchEntity {
    if (currentRound >= 0) {
      const round = rounds.get(currentRound);
      if (round) {
        return round.find((match) => match.registration1Id === registrationId || match.registration2Id === registrationId);
      }
    }

    return null;
  }

  private static lowerPowerOfTwo(x: number): number {
    let i = 0;
    let res = 1;
    while (res < x) {
      i++;
      res *= 2;
    }
    return i;
  }
}
