import { TournamentEntity } from '../entities';

export class TournamentsHelper {
  public static isTournamentInProgress(tournament: TournamentEntity): boolean {
    const today = new Date();

    return tournament.startDate.getTime() >= today.getTime() && tournament.endDate.getTime() < today.getTime();
  }

  public static isTournamentComing(tournament: TournamentEntity): boolean {
    return tournament.startDate.getTime() > new Date().getTime();
  }

  public static sortTournamentsByStartDate(tournaments: TournamentEntity[]): TournamentEntity[] {
    return tournaments.sort((a: TournamentEntity, b: TournamentEntity) => {
      if (a.startDate?.getTime() > b.startDate?.getTime()) {
        return 1;
      } else if (a.startDate?.getTime() < b.startDate?.getTime()) {
        return -1;
      } else {
        return 0;
      }
    });
  }
}
