import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationModalComponent } from './confirmation-modal.component';
import { CoreModule } from 'sevenfallen-core';

@NgModule({
  declarations: [ConfirmationModalComponent],
  imports: [CommonModule, CoreModule],
})
export class ConfirmationModalModule {}
