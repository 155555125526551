import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

export class TimeHelper {
  public static ngbDateFromControlValue(controlValue: any): NgbDate {
    if (controlValue) {
      return new NgbDate(controlValue.year, controlValue.month, controlValue.day);
    }
    return null;
  }

  public static timeIsBefore(time1: { hour: number; minute: number }, time2: { hour: number; minute: number }): boolean {
    return TimeHelper.CompareTime(time1, time2) === -1;
  }

  public static timeIsAfter(time1: { hour: number; minute: number }, time2: { hour: number; minute: number }): boolean {
    return TimeHelper.CompareTime(time1, time2) === 1;
  }

  private static CompareTime(time1: { hour: number; minute: number }, time2: { hour: number; minute: number }): number {
    if (time1.hour > time2.hour) {
      return 1;
    } else if (time1.hour < time2.hour) {
      return -1;
    } else {
      if (time1.minute > time2.minute) {
        return 1;
      } else if (time1.minute < time2.minute) {
        return -1;
      }
    }
    return 0;
  }
}
