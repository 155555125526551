import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-autocomplete-item',
  templateUrl: './autocomplete-item.component.html',
  styleUrls: ['./autocomplete-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteItemComponent<T> implements AfterViewInit, OnDestroy {
  @Input() item: T;
  @Input() preSelected: boolean;

  @Output() selected = new EventEmitter<void>();

  @ViewChild('itemPortal') templateRef: TemplateRef<void>;

  private itemView: EmbeddedViewRef<void>;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public get _view() {
    return this.itemView;
  }

  ngAfterViewInit(): void {
    this.itemView = this.templateRef.createEmbeddedView(null);
    this.changeDetectorRef.detach();
  }

  ngOnDestroy(): void {
    if (this.itemView) {
      this.itemView.destroy();
    }
  }
}
