import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnotifyService } from 'ng-snotify';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationModalComponent} from './ui/confirmation-modal/confirmation-modal.component';
import {ReloadModalComponent} from './ui/reload-modal/reload-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private translateService: TranslateService, private snotifyService: SnotifyService, private modalService: NgbModal) {
    translateService.use('fr_FR');
  }

  public ngOnInit(): void {
    this.launchRefreshTimer();
  }

  private launchRefreshTimer() {
    setTimeout(() => this.openRefreshModal(), 1800000);
  }

  private openRefreshModal() {
    const modalRef = this.modalService.open(ReloadModalComponent);
    const refreshTimeout = setTimeout(() => location.reload(), 30000);
    modalRef.result.then(async (result) => {
      if (result === 'confirm') {
        location.reload();
      }
      if (result === 'cancel') {
        clearTimeout(refreshTimeout);
        this.launchRefreshTimer();
      }
    });
  }
}


