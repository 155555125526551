import { Entity, EntityProperty } from 'ng-fluxify';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'Category',
    route: '/categories',
  }),
)
export class CategoryEntity extends Abstract7fallenEntity {
  @EntityProperty({ type: String })
  public label: string;

  @EntityProperty({ type: Number })
  public categoryGroupId: number;

  @EntityProperty({ type: Number })
  public order: number;

  @EntityProperty({ type: Boolean })
  public active: boolean;

  @EntityProperty({ type: String })
  public image: string;

  @EntityProperty({ type: String })
  public authorizedRoles: string;
}
