import { Pipe, PipeTransform } from '@angular/core';
import { AvatarEntity, RegistrationEntity } from 'sevenfallen-core';
import { ScoreEntity } from '../entities/score.entity';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'avatar',
})
export class AvatarPipe implements PipeTransform {
  transform(avatar: AvatarEntity): string {
    return `/assets/avatars/${avatar.image}`;
  }
}
