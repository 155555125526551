import { Entity, EntityProperty, ManyToOne } from 'ng-fluxify';
import { forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { CountryEntity } from './country.entity';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';
import { AvatarEntity } from './avatar.entity';
import { UserRolesEnum } from '../enums/user-roles.enum';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'User',
    route: '/users',
  }),
)
export class UserEntity extends Abstract7fallenEntity<string> {
  @EntityProperty({ type: String, primary: true })
  public id: string;

  @EntityProperty({ type: String })
  public firstname: string;

  @EntityProperty({ type: String })
  public lastname: string;

  @EntityProperty({ type: String })
  public pseudo: string;

  @EntityProperty({ type: String })
  public email: string;

  @EntityProperty({ type: String })
  public discord: string;

  @EntityProperty({ type: String })
  public provider: string;

  @EntityProperty({ type: Date })
  public registrationDate: Date;

  @EntityProperty({ type: Number })
  public countryId: number;

  @ManyToOne({ entity: forwardRef(() => CountryEntity), foreignKey: 'countryId' })
  public country$: Observable<CountryEntity>;

  @EntityProperty({ type: Number })
  public avatarId: number;

  @ManyToOne({ entity: forwardRef(() => AvatarEntity), foreignKey: 'avatarId' })
  public avatar$: Observable<AvatarEntity>;

  @EntityProperty({ type: Number })
  public role: UserRolesEnum;

  @EntityProperty({ type: Boolean })
  public isImmortal: boolean;

  @EntityProperty({ type: String })
  public idToMerge: string;

  @EntityProperty({ type: Boolean })
  public keepMergedHistory: boolean;
}
