import { Entity, EntityProperty, ManyToOne } from 'ng-fluxify';
import { forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { RegistrationEntity } from './registration.entity';
import { MatchState } from '../services/interfaces';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'Match',
    route: '/matches',
  }),
)
export class MatchEntity extends Abstract7fallenEntity {
  @EntityProperty({ type: Number })
  public registration1Id: number;

  @ManyToOne({ entity: forwardRef(() => RegistrationEntity), foreignKey: 'registration1Id' })
  public registration1$: Observable<RegistrationEntity>;

  @EntityProperty({ type: Number })
  public registration2Id: number;

  @ManyToOne({ entity: forwardRef(() => RegistrationEntity), foreignKey: 'registration2Id' })
  public registration2$: Observable<RegistrationEntity>;

  @EntityProperty({ type: Number })
  public registration1Point: number;

  @EntityProperty({ type: Number })
  public registration2Point: number;

  @EntityProperty({ type: Number })
  public round: number;

  @EntityProperty({ type: Number })
  public tournamentId: number;

  @EntityProperty({ type: Number })
  public state: MatchState;
}
