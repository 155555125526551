import { Entity, EntityProperty } from 'ng-fluxify';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'GenerateScore',
    route: '/scores/generate',
  }),
)
export class GenerateScoreEntity extends Abstract7fallenEntity {
  @EntityProperty({ type: Number })
  public tournamentId: number;

  @EntityProperty({ type: Number })
  public roundMaxCount: number;

  @EntityProperty({ type: Number })
  public roundCount: number;
}
