import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from './avatar.component';
import { AvatarService } from './avatar.service';

@NgModule({
  imports: [CommonModule],
  declarations: [AvatarComponent],
  exports: [AvatarComponent],
})
export class AvatarModule {
  static forRoot(): ModuleWithProviders<AvatarModule> {
    return {
      ngModule: AvatarModule,
      providers: [AvatarService],
    };
  }
}
