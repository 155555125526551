import { LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SnotifyService, ToastDefaults } from 'ng-snotify';
import { AngularFireModule } from '@angular/fire';
import { NgFluxifyModule } from 'ng-fluxify';

import { AppReadyGuard, CoreModule, IAuthenticationService } from 'sevenfallen-core';

import { AppComponent } from './app.component';
import { SpinnerService, UiModule } from 'sevenfallen-ui';

import { environment } from '../environments/environment';
import { SharedModule } from './shared';

registerLocaleData(localeFr, 'fr');

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/main.module').then((m) => m.MainModule),
    canActivate: [AppReadyGuard],
  },
  {
    path: 'authentication',
    loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
    canActivate: [AppReadyGuard],
  },
  { path: '**', redirectTo: '' },
];

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule.forRoot({ production: environment.production, apiUrl: environment.apiURL }),
    UiModule.forRoot(),
    SharedModule.forRoot(),
    NgFluxifyModule.initialize({
      enableStoreLogger: !environment.production,
      enableDynamicStateMutability: true,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'fr_FR',
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
  ],
  providers: [
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
    {
      provide: LOCALE_ID,
      useValue: 'fr',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private authenticationService: IAuthenticationService, private spinnerService: SpinnerService) {
    if (!this.spinnerService.isSpinnerShown) {
      this.spinnerService.showSpinner({
        message: 'Chargement des données...',
      });
    }

    this.authenticationService.initialize().then(() => this.spinnerService.hideSpinner());
  }
}
