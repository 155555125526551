<div
  *ngIf="tournament"
  [routerLink]="organizerDisplay ? ['/organize/tournament', tournament.id] : ['/tournament', tournament.id]"
  class="block"
  [ngClass]="{'full-height': !lightMode}"
>
  <div class="cover" style="background-image:url('{{categoryImage$|async}}');"></div>
  <div class="infos">
    <div class="title">
      <p class="font-title">{{ tournament.name | uppercase }}</p>
    </div>
    <div class="other" *ngIf="!lightMode">
      <p *ngIf="tournament.startDate">
        <i class="bi-calendar"></i> {{ tournament.startDate | date : 'dd/MM/yyyy' }} - {{ tournament.startDate | date : 'HH' }}h{{
        tournament.startDate | date : 'mm' }}
      </p>
      <p *ngIf="!tournament.isOnline">
        <ng-container *ngIf="(tournament.country$ | async)?.code as code">
          <i class="bi-pin"></i> {{ tournament.city }} - {{ 'locales.' + code + '.country' | translate }}
        </ng-container>
      </p>
      <p>
        <span *ngIf="(registrations$ | async) as registrations else noRegistration">
          <i class="bi-people"></i> {{ tournament.maxRegistrations - registrations.length }} {{ ((tournament.maxRegistrations -
          registrations.length) > 1 ? ('utils.slots-plural') : ('utils.slots')) | translate }} - {{registrations.length}}
          {{(registrations.length > 1 ? ('pages.config-tournament.registered-plural') : ('pages.config-tournament.registered')) | translate
          | titlecase}}
        </span>
        <ng-template #noRegistration
          ><i class="bi-people"></i> {{ tournament.maxRegistrations }} {{'utils.slots-plural' | translate}}</ng-template
        >
      </p>
    </div>
  </div>
  <div
    *ngIf="organizerDisplay && !lightMode else defaultDisplay"
    [ngClass]="{
    'bg-secondary': tournament.status === tournamentsStatus.DRAFT,
    'bg-success': tournament.status === tournamentsStatus.OPEN,
    'bg-danger': tournament.status === tournamentsStatus.FULL,
    'bg-primary': tournament.status === tournamentsStatus.ONGOING,
    'bg-dark': tournament.status === tournamentsStatus.DONE
  }"
    class="status"
  >
    <ng-container [ngSwitch]="tournament.status">
      <p *ngSwitchCase="tournamentsStatus.DRAFT">{{ 'utils.status.draft' | translate }}</p>
      <p *ngSwitchCase="tournamentsStatus.OPEN">{{ 'utils.status.registration-open' | translate }}</p>
      <p *ngSwitchCase="tournamentsStatus.FULL">{{ 'utils.status.full' | translate }}</p>
      <p *ngSwitchCase="tournamentsStatus.DONE">{{ 'utils.status.done' | translate }}</p>
      <p *ngSwitchCase="tournamentsStatus.ONGOING">{{ 'utils.status.on-going' | translate }}</p>
    </ng-container>
  </div>
  <ng-template #defaultDisplay>
    <div *ngIf="!lightMode" [ngClass]="{'bg-warning': tournamentIsFull$ | async }" class="status">
      <ng-container *ngIf="tournamentIsFull$ | async">
        <p>{{ 'utils.status.full' | translate }}</p>
      </ng-container>
    </div>
  </ng-template>
  <ng-container *ngIf="player && tournament.status === tournamentsStatus.DONE">
    <div *ngIf="((player | getLastScore) | async)?.rank as rank" class="bg-primary status">
      <p>{{ 'utils.rank' | translate }} : {{ rank }}</p>
    </div>
  </ng-container>
</div>
