import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthProviderService } from '../interfaces';

export class RedirectAuthProviderService extends AuthProviderService {
  constructor(private angularFireAuth: AngularFireAuth, private authProvider: firebase.auth.AuthProvider) {
    super();
  }

  async execute(): Promise<void> {
    await this.angularFireAuth.signInWithRedirect(this.authProvider);
  }
}
