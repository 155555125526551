import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AvatarService } from './avatar.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnChanges {
  @Input() src: string;
  @Input() alt: string;

  computedSrc: SafeResourceUrl;
  loaded = false;
  progress = 0;

  @HostBinding('style.clip-path')
  get clipPath(): string {
    const points = ['50% 50%'];

    const steps = [
      { percent: 0, x: 50, y: 0 },
      { percent: 100 / 8, x: 100, y: 0 },
      { percent: 25 + 100 / 8, x: 100, y: 100 },
      { percent: 50 + 100 / 8, x: 0, y: 100 },
      { percent: 75 + 100 / 8, x: 0, y: 0 },
      { percent: 100, x: 50, y: 0 },
    ];

    steps.every((step, index) => {
      if (this.progress >= step.percent) {
        points.push(`${step.x}% ${step.y}%`);
        return true;
      }

      const previousStep = steps[index - 1];

      const start = previousStep.x === step.x ? previousStep.y : previousStep.x;
      const end = previousStep.x === step.x ? step.y : step.x;

      const position = ((this.progress - previousStep.percent) * 100) / (step.percent - previousStep.percent);
      const coord = start > end ? Math.max(start - position, 0) : Math.min(start + position, 100);

      const x = previousStep.x === step.x ? step.x : coord;
      const y = previousStep.y === step.y ? step.y : coord;
      points.push(`${x}% ${y}%`);

      return false;
    });

    return `polygon(${points.join(', ')})`;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef, private domSanitizer: DomSanitizer, private avatarService: AvatarService) {}

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.src && this.src) {
      this.loaded = false;
      this.progress = 0;

      this.computedSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(
        await this.avatarService.loadAvatar(this.src, (progress) => {
          this.progress = progress;
          this.changeDetectorRef.detectChanges();
        }),
      );

      this.loaded = true;
      this.progress = 100;
      this.changeDetectorRef.detectChanges();
    }
  }
}
