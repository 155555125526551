import { Component, Input, OnInit } from '@angular/core';
import { RegistrationEntity, RegistrationStateEnum, TournamentEntity, TournamentsStatusEnum, UserEntity } from 'sevenfallen-core';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { trackByPrimary } from 'ng-fluxify';

@Component({
  selector: 'app-tournament-card',
  templateUrl: './tournament-card.component.html',
  styleUrls: ['./tournament-card.component.scss'],
})
export class TournamentCardComponent implements OnInit {
  @Input() tournament: TournamentEntity;
  @Input() organizerDisplay = false;
  @Input() player: RegistrationEntity;
  @Input() lightMode = false;

  tournamentsStatus = TournamentsStatusEnum;

  categoryImage$: Observable<string>;
  tournamentIsFull$: Observable<boolean>;
  registrations$: Observable<RegistrationEntity[]>;

  trackByFn = trackByPrimary;

  ngOnInit(): void {
    this.registrations$ = RegistrationEntity.readAll<RegistrationEntity>().pipe(
      map((registrations) => registrations.filter((registration) => registration.tournamentId === this.tournament.id)),
      map((rs) => (this.tournament.isRegistrationValidated ? rs.filter((r) => r.state === RegistrationStateEnum.VALIDATED) : rs)),
    );

    this.tournamentIsFull$ = this.registrations$.pipe(
      map(
        (registrations) =>
          registrations.length >= this.tournament.maxRegistrations || this.tournament.status === this.tournamentsStatus.FULL,
      ),
    );

    this.categoryImage$ = this.tournament.category$.pipe(map((category) => category.image));
  }
}
