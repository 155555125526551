<div class="modal-header">
  <h4 class="modal-title">{{ 'utils.confirmation' | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p [innerHTML]="message"></p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-success" (click)="activeModal.close('confirm')">{{ 'utils.confirm' | translate }}</button>
  <button type="button" class="btn btn-outline-light" (click)="activeModal.close('cancel')">{{ 'utils.cancel' | translate }}</button>
</div>
