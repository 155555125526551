import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isDateSuperior',
})
export class IsDateSuperiorPipe implements PipeTransform {
  transform(date1: Date, date2: Date): boolean {
    return date1.getTime() > date2.getTime();
  }
}
