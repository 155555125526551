import { Entity, EntityProperty, ManyToOne } from 'ng-fluxify';
import { forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { UserEntity } from './user.entity';
import { TournamentEntity } from './tournament.entity';
import { DivinityEntity } from './divinity.entity';
import { TempleEntity } from './temple.entity';
import { KingdomEntity } from './kingdom.entity';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'Registration',
    route: '/registrations',
  }),
)
export class RegistrationEntity extends Abstract7fallenEntity {
  @EntityProperty({ type: Number })
  public tournamentId: number;

  @ManyToOne({ entity: forwardRef(() => TournamentEntity), foreignKey: 'tournamentId' })
  public tournament$: Observable<TournamentEntity>;

  @EntityProperty({ type: String })
  public userId: string;

  @ManyToOne({ entity: forwardRef(() => UserEntity), foreignKey: 'userId' })
  public user$: Observable<UserEntity>;

  @EntityProperty({ type: Number })
  public divinityId: number;

  @ManyToOne({ entity: forwardRef(() => DivinityEntity), foreignKey: 'divinityId' })
  public divinity$: Observable<DivinityEntity>;

  @EntityProperty({ type: Number })
  public templeId: number;

  @ManyToOne({ entity: forwardRef(() => TempleEntity), foreignKey: 'templeId' })
  public temple$: Observable<TempleEntity>;

  @EntityProperty({ type: Number })
  public kingdomId: number;

  @ManyToOne({ entity: forwardRef(() => KingdomEntity), foreignKey: 'kingdomId' })
  public kingdom$: Observable<KingdomEntity>;

  @EntityProperty({ type: Number })
  public state: RegistrationStateEnum;

  @EntityProperty({ type: Number })
  public drop: number = null;

  @EntityProperty({ type: Number })
  public fv: number = 0;

  public tb4: any;
}

export enum RegistrationStateEnum {
  WAITING,
  VALIDATED,
  CHECKED_IN,
}
