<input
  #input
  appInput
  [placeholder]="placeholder"
  [disabled]="isDisabled"
  (keyup)='$event.key !== "Enter" ? search(input.value) : null'
  (focusin)="search(lastSearch, false)"
  (keydown.arrowDown)="onArrowDown($event)"
  (keydown.arrowUp)="onArrowUp($event)"
  (keydown.enter)="onEnter($event)"
/>

<ul [hidden]="!isOpened" class="choices">
  <ng-container *ngFor="let item of displayedResults | slice:0:maxItems; trackBy: trackByItem" [ngTemplateOutlet]="item.templateRef">
  </ng-container>

  <li *ngIf="isEmpty" class="empty">Aucun élément</li>
</ul>
