import { AbstractEntity, Entity, EntityProperty, RestEntityDescriptor } from 'ng-fluxify';

@Entity(
  new RestEntityDescriptor({
    name: 'Pseudo',
    route: '/pseudos',
  }),
)
export class PseudoEntity extends AbstractEntity {
  @EntityProperty({ type: String, primary: true })
  public pseudo: string;
}
