import { Entity, EntityProperty } from 'ng-fluxify';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'Avatar',
    route: '/avatars',
  }),
)
export class AvatarEntity extends Abstract7fallenEntity {
  @EntityProperty({ type: String })
  public image: string;
}
