export class SeedHelper {
  static getSeed(nbPlayers: number): { p1: number; p2: number }[] {
    if (!(Math.log2(nbPlayers) % 1 === 0)) {
      throw new Error('Not a power of 2');
    }

    const power = Math.log(nbPlayers) / Math.log(2);

    const sequence = Array.from({ length: power - 1 }).reduce(
      (acc: any) => {
        const length = acc.length * 2 + 1;
        return Array.from({ length: length - 1 }, (_, k) => (k % 2 ? length - acc[(k - 1) / 2] : acc[k / 2]));
      },
      [1, 2],
    );

    return Array.from({ length: nbPlayers / 2 }).map((_, index) => ({ p1: sequence[index * 2], p2: sequence[index * 2 + 1] }));
  }
}
