import { forwardRef } from '@angular/core';
import { Entity, EntityProperty, ManyToOne } from 'ng-fluxify';
import { Observable } from 'rxjs';
import { UserEntity } from './user.entity';
import { TournamentEntity } from './tournament.entity';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'Organizer',
    route: '/organizers',
  }),
)
export class OrganizerEntity extends Abstract7fallenEntity {
  @EntityProperty({ type: Number })
  public tournamentId: number;

  @ManyToOne({ entity: forwardRef(() => TournamentEntity), foreignKey: 'tournamentId' })
  public tournament$: Observable<TournamentEntity>;

  @EntityProperty({ type: String })
  public userId: string;

  @ManyToOne({ entity: forwardRef(() => UserEntity), foreignKey: 'userId' })
  public user$: Observable<UserEntity>;
}
