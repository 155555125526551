<div class="modal-header">
  <h4 class="modal-title">{{ 'utils.refresh' | translate }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" [innerHTML]="'pages.main.refresh-message' | translate">
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-success" (click)="activeModal.close('confirm')">{{ 'utils.refresh' | translate }} ({{timer}})</button>
  <button type="button" class="btn btn-outline-light" (click)="activeModal.close('cancel')">{{ 'utils.later' | translate }}</button>
</div>
