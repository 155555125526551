import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IAuthenticationService } from '../services/interfaces';

@Injectable()
export class GuestOnlyGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: IAuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authenticationService.isAuthenticated$.pipe(
      switchMap((isAuthenticated) => {
        if (!isAuthenticated) {
          throw new Error();
        }

        return this.authenticationService.currentUser$;
      }),
      map((user) => {
        if (user) {
          return this.router.createUrlTree(['/home']);
        }

        if (state.url.indexOf('register') === -1) {
          return this.router.createUrlTree(['/authentication/register']);
        }

        throw new Error();
      }),
      catchError((_) => of(true)),
    );
  }
}
