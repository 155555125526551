import { Entity, EntityProperty, ManyToOne } from 'ng-fluxify';
import { forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { MatchEntity, RegistrationEntity, TournamentEntity } from 'sevenfallen-core';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'Score',
    route: '/scores',
  }),
)
export class ScoreEntity extends Abstract7fallenEntity {
  @EntityProperty({ type: Number })
  public byes: number;

  @EntityProperty({ type: Number })
  public matchPoints: number;

  @EntityProperty({ type: Number })
  public matchesWinPercent: number;

  @EntityProperty({ type: Number })
  public matchesLoose: number;

  @EntityProperty({ type: Number })
  public matchesWin: number;

  @EntityProperty({ type: Number })
  public buchholz: number;

  @EntityProperty({ type: Number })
  public koya: number;

  @EntityProperty({ type: Number })
  public kus: number;

  @EntityProperty({ type: Number })
  public sodos: number;

  @EntityProperty({ type: Number })
  public lastRoundLost?: number;

  @EntityProperty({ type: Number })
  public registrationId: number;

  @ManyToOne({ entity: forwardRef(() => RegistrationEntity), foreignKey: 'registrationId' })
  public registration$?: Observable<RegistrationEntity>;

  @EntityProperty({ type: Number })
  public tournamentId: number;

  @ManyToOne({ entity: forwardRef(() => TournamentEntity), foreignKey: 'tournamentId' })
  public tournament$?: Observable<RegistrationEntity>;

  @EntityProperty({ type: Number })
  public round?: number;

  @EntityProperty({ type: Number })
  public rank: number;

  public matches?: MatchEntity[];
}
