export * from './abstract-7fallen.entity';
export * from './avatar.entity';
export * from './cancel-round.entity';
export * from './category.entity';
export * from './category-group.entity';
export * from './city.entity';
export * from './country.entity';
export * from './generate-score.entity';
export * from './divinity.entity';
export * from './kingdom.entity';
export * from './match.entity';
export * from './new-round.entity';
export * from './organizer.entity';
export * from './pseudo.entity';
export * from './registration.entity';
export * from './temple.entity';
export * from './tournament.entity';
export * from './user.entity';
