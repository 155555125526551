import { Pipe, PipeTransform } from '@angular/core';
import {RegistrationEntity, UserEntity} from 'sevenfallen-core';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

@Pipe({
  name: 'sortRegistration',
})
export class SortRegistrationPipe implements PipeTransform {
  async transform<T>(values: RegistrationEntity[]): Promise<RegistrationEntity[]> {
    const users = await UserEntity.readAll<UserEntity>().pipe(take(1)).toPromise();
    const sortedRegistrations = values.sort((a: RegistrationEntity, b: RegistrationEntity) => {
      const userA = users.find((user: UserEntity) => a.userId === user.id);
      const userB = users.find((user: UserEntity) => b.userId === user.id);
      if(!userA?.pseudo || !userB?.pseudo) {
        return 0;
      }

      const pseudoA = userA.pseudo.trim().toUpperCase();
      const pseudoB = userB.pseudo.trim().toUpperCase();

      if(pseudoA > pseudoB) {
        return 1;
      }
      if(pseudoA < pseudoB){
        return -1;
      }
      return 0;
    });
    return sortedRegistrations;
  }

}
