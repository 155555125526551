import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class QueryParamsService {
  readonly params: Map<string, any>;

  constructor(private location: Location, private router: Router, private route: ActivatedRoute) {
    this.params = new Map<string, any>(
      Object.keys(this.route.snapshot.queryParams).map((key) => [key, this.route.snapshot.queryParams[key]]),
    );

    this.router.events.subscribe((_) => {
      const params = this.router.routerState.snapshot.root.queryParams;
      Object.keys(params).forEach((key) => this.params.set(key, params[key]));
    });
  }

  setParam(key: string, value: string): void {
    this.params.set(key, value);

    this.write();
  }

  setParams(params: { [key: string]: string }): void {
    Object.keys(params).forEach((key) => this.params.set(key, params[key]));

    this.write();
  }

  removeParam(key: string): void {
    this.params.delete(key);

    this.write();
  }

  clear(): void {
    this.params.clear();
    this.write();
  }

  private write(): void {
    this.location.replaceState(
      this.router
        .parseUrl(this.router.url)
        .root.children.primary.segments.map((it) => it.path)
        .join('/'),
      [...this.params.keys()].map((key) => `${key}=${this.params.get(key)}`).join('&'),
    );
  }
}
