import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { IAuthenticationService } from '../services/interfaces';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class AppReadyGuard implements CanActivate {
  constructor(private router: Router, private authenticationService: IAuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.isReady$.pipe(filter((isReady) => isReady));
  }
}
