import { Entity, EntityProperty } from 'ng-fluxify';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'Divinity',
    route: '/divinities',
  }),
)
export class DivinityEntity extends Abstract7fallenEntity {
  @EntityProperty({ type: String })
  public name: string;
  @EntityProperty({ type: Boolean })
  public active: boolean;
}
