import { Pipe, PipeTransform } from '@angular/core';
import { RegistrationStateEnum } from '../entities';

@Pipe({
  name: 'matchNumberToLabel',
})
export class MatchNumberToLabelPipe implements PipeTransform {
  transform(numberOfMatch: number): string {
    return numberOfMatch === 1 ? '' : `1/${numberOfMatch}`;
  }
}
