import { NgModule } from '@angular/core';
import { PaginatePipe } from './paginate.pipe';
import { SortPipe } from './sort.pipe';
import { SortByPipe } from './sort-by.pipe';
import { SortRegistrationPipe } from './sort-registration.pipe';

@NgModule({
  declarations: [PaginatePipe, SortPipe, SortByPipe, SortRegistrationPipe],
  exports: [PaginatePipe, SortPipe, SortByPipe, SortRegistrationPipe],
})
export class PipesModule {}
