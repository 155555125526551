import { Entity, EntityProperty } from 'ng-fluxify';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'Country',
    route: '/countries',
  }),
)
export class CountryEntity extends Abstract7fallenEntity {
  @EntityProperty({ type: String })
  public code: string;

  @EntityProperty({ type: Number })
  public order: number;
}
