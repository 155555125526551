<app-avatar (click)="isOpened = true" [src]="avatar?.src" data-edit-text="Modifier"></app-avatar>

<div *ngIf="isOpened" (click)="isOpened = false" class="modal fade show">
  <div class="modal-dialog" (click)="$event.stopPropagation()">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="btn-close" (click)="isOpened = false"></button>
      </div>
      <div class="modal-body">
        <div class="row g-2">
          <div *ngFor="let avatar of avatars; trackBy: trackByFn" class="item col-3" [class.current]="avatar.id === avatarId">
            <app-avatar [src]="avatar.src" (click)="selectAvatar(avatar.id)"></app-avatar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
