import { Directive, HostBinding, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: 'input[appInput]',
})
export class InputDirective {
  @Input()
  @HostBinding('class.form-control')
  isFormControl = true;

  @HostBinding('class.is-valid') get isValid(): boolean {
    return this.control?.valid && this.control?.touched;
  }

  @HostBinding('class.is-invalid') get isInvalid(): boolean {
    return !this.control?.valid && this.control?.touched;
  }

  constructor(@Self() @Optional() private control: NgControl) {}
}
