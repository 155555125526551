import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  private static isObject(obj: any): boolean {
    return (typeof obj === 'object' || typeof obj === 'function') && obj !== null;
  }

  transform<T>(values: T[], property?: string, revert?: boolean): T[] {
    if (!values) {
      return values;
    }

    return values.sort((a, b) => {
      // @ts-ignore
      const valueA = property && SortByPipe.isObject(a) ? Reflect.get(a, property) : a;
      // @ts-ignore
      const valueB = property && SortByPipe.isObject(b) ? Reflect.get(b, property) : b;

      const multiple = revert ? -1 : 1;

      if (typeof valueA === 'string') {
        return valueA.localeCompare(valueB);
      }

      if (valueA > valueB) {
        return 1 * multiple;
      }
      if (valueA < valueB) {
        return -1 * multiple;
      }

      return 0;
    });
  }
}
