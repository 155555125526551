import { Pipe, PipeTransform } from '@angular/core';
import { RegistrationEntity, RegistrationStateEnum, TournamentEntity } from '../entities';

@Pipe({
  name: 'registrationNotComplete',
})
export class RegistrationNotCompletePipe implements PipeTransform {
  transform(tournament: TournamentEntity, registrations: RegistrationEntity[]): boolean {
    if (tournament.isRegistrationValidated && !tournament.isCheckInActive) {
      return registrations.some((registration: RegistrationEntity) => registration.state < RegistrationStateEnum.VALIDATED);
    }
    if (tournament.isCheckInActive) {
      return registrations.some((registration: RegistrationEntity) => registration.state < RegistrationStateEnum.CHECKED_IN);
    }
    return false;
  }
}
