import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AvatarModule } from '../avatar';

import * as Components from './components';
import * as Directives from './directives';

@NgModule({
  imports: [CommonModule, FormsModule, AvatarModule],
  declarations: [
    Components.AutocompleteComponent,
    Components.AutocompleteItemComponent,
    Components.AvatarPickerComponent,
    Directives.InputDirective,
    Directives.SelectDirective,
  ],
  exports: [
    Components.AutocompleteComponent,
    Components.AutocompleteItemComponent,
    Components.AvatarPickerComponent,
    Directives.InputDirective,
    Directives.SelectDirective,
  ],
})
export class FormModule {}
