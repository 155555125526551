import { Entity, EntityProperty } from 'ng-fluxify';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'CancelRound',
    route: '/matches/cancelround',
  }),
)
export class CancelRoundEntity extends Abstract7fallenEntity {
  @EntityProperty({ type: Number })
  public tournamentId: number;

  @EntityProperty({ type: Number })
  public currentRound: number;
}
