import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface Avatar {
  id: number;
  src: string;
}

@Component({
  selector: 'app-avatar-picker',
  templateUrl: './avatar-picker.component.html',
  styleUrls: ['./avatar-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AvatarPickerComponent),
      multi: true,
    },
  ],
})
export class AvatarPickerComponent implements ControlValueAccessor {
  @Input() avatars: Avatar[] = [];

  avatarId?: number;
  isDisabled = false;
  isOpened = false;

  trackByFn = (_, avatar: Avatar) => avatar.id;

  get avatar(): Avatar {
    return this.avatars.find((avatar) => avatar.id === this.avatarId);
  }

  private onChange?: (_: any) => void;
  private onTouched?: (_: any) => void;

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: (_: any) => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(value: number): void {
    this.avatarId = value;
  }

  selectAvatar(value: number): void {
    this.avatarId = value;
    this.isOpened = false;

    if (this.onChange) {
      this.onChange(this.avatarId);
    }

    if (this.onTouched) {
      this.onTouched(this.avatarId);
    }
  }
}
