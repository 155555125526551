import { Entity, EntityProperty } from 'ng-fluxify';
import { Abstract7fallenEntity } from './abstract-7fallen.entity';
import { CustomRestEntityDescriptor } from '../descriptors';

@Entity(
  new CustomRestEntityDescriptor({
    name: 'City',
    route: '/cities',
  }),
)
export class CityEntity extends Abstract7fallenEntity {
  @EntityProperty({ type: String })
  public name: string;
}
